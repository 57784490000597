import React from "react";

import logo from "./logo.png";
import BgVideo from "./background.mp4";

import "./App.css";
import "animate.css";

const App = () => {
  return (
    <div style={{ position: "relative" }}>
      <video
        playsInline
        autoPlay
        muted
        loop
        id="bgvid"
        style={{
          objectFit: "cover",
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
          opacity: "0.15",
        }}
      >
        <source src={BgVideo} type="video/mp4" />
      </video>

      <div
        style={{
          alignItems: "center",
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <img
          className="animate__animated animate__fadeInUp"
          width="100px"
          src={logo}
          alt="Logo"
        />

        <h1
          className="animate__animated animate__fadeInUp"
          style={{ marginTop: "20px", fontWeight: "900", textAlign: "center" }}
        >
          The Future of Digital Product Development
        </h1>
        <p
          style={{
            fontSize: "16px",
            letterSpacing: "3px",
            marginTop: "14px",
            opacity: "0.5",
          }}
        >
          COMING SOON
        </p>
      </div>
    </div>
  );
};

export default App;
